@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,500;1,100;1,200;1,400&display=swap");
h1,
h2,
h3,
h4,
h5,
li,
dt,
dd,
p,
label,
button,
input,
textarea,
select,
dt,
dd {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important; }

a {
  color: inherit;
  text-decoration: none; }

img {
  width: 100%;
  height: auto; }

.inner-1 {
  width: 100%;
  max-width: 1306px;
  padding: 0 15px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

@media screen and (max-width: 1175px) {
  .inner-1 {
    padding: 0; } }

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

#loader .spring-spinner,
#loader .spring-spinner * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#loader .spring-spinner {
  height: 30px;
  width: 30px; }

#loader .spring-spinner .spring-spinner-part {
  overflow: hidden;
  height: calc(30px / 2);
  width: 30px; }

#loader .spring-spinner .spring-spinner-part.bottom {
  -webkit-transform: rotate(180deg) scale(-1, 1);
  -ms-transform: rotate(180deg) scale(-1, 1);
  transform: rotate(180deg) scale(-1, 1); }

#loader .spring-spinner .spring-spinner-rotator {
  width: 30px;
  height: 30px;
  border: calc(20px / 7) solid transparent;
  border-right-color: #ececec;
  border-top-color: #dfdfdf;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: spring-spinner-animation 3s ease-in-out infinite;
  animation: spring-spinner-animation 3s ease-in-out infinite;
  -webkit-transform: rotate(-200deg);
  -ms-transform: rotate(-200deg);
  transform: rotate(-200deg); }

@-webkit-keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7); }
  25% {
    border-width: calc(60px / 23.33); }
  50% {
    -webkit-transform: rotate(115deg);
    transform: rotate(115deg);
    border-width: calc(60px / 7); }
  75% {
    border-width: calc(60px / 23.33); }
  100% {
    border-width: calc(60px / 7); } }

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7); }
  25% {
    border-width: calc(60px / 23.33); }
  50% {
    -webkit-transform: rotate(115deg);
    transform: rotate(115deg);
    border-width: calc(60px / 7); }
  75% {
    border-width: calc(60px / 23.33); }
  100% {
    border-width: calc(60px / 7); } }

header {
  padding: 100px 0 35px 0; }

header .hd-logo {
  width: 75%;
  max-width: 450px;
  margin: 0 auto; }

footer {
  margin-top: 65px;
  padding-top: 15px;
  background-color: #181818; }

footer .menu-main-wrap .menu-main {
  height: 80px;
  background-color: #181818;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

footer .menu-main-wrap .menu-main .ft-logo {
  width: 220px; }

footer .menu-main-wrap .menu-main ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

footer .menu-main-wrap .menu-main ul a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

footer .menu-main-wrap .menu-main ul a.sns-link {
  margin-right: 10px; }

footer .menu-main-wrap .menu-main ul a.sns-link:last-child {
  margin-right: 0; }

footer .menu-main-wrap .menu-main ul a li {
  color: #fff; }

footer .menu-main-wrap .menu-main ul a li p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 1px; }

footer .menu-main-wrap .menu-main ul a li p span {
  width: 100%;
  text-align: center;
  font-size: 8px;
  margin-top: 6px;
  letter-spacing: 0px; }

.inner {
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

#studio-detail {
  margin: 50px 0; }
  #studio-detail .studio-box {
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 25px #00000020;
    box-shadow: 0px 0px 25px #00000020; }
    #studio-detail .studio-box p {
      font-weight: 600 !important;
      width: 100%;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex; }
      #studio-detail .studio-box p.studioName {
        padding-left: 1em; }
      #studio-detail .studio-box p.address img {
        width: 12px; }
      #studio-detail .studio-box p.tel img {
        width: 18px; }
      #studio-detail .studio-box p.mail img {
        width: 18px; }
      #studio-detail .studio-box p span {
        width: 30px;
        text-align: center; }
    #studio-detail .studio-box iframe {
      width: 100%;
      height: 300px; }
  #studio-detail .studio-btn {
    margin-top: 50px;
    text-align: center; }
    #studio-detail .studio-btn .studio-link {
      cursor: pointer;
      content: ">";
      background-color: #fa3f3f;
      font-size: 25px;
      color: #fff;
      border: none;
      padding: 0.5em;
      width: 95%;
      max-width: 600px; }
      @media screen and (max-width: 768px) {
        #studio-detail .studio-btn .studio-link {
          font-size: 18px; } }

main p,
main h3 {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
  font-weight: 500; }

main p {
  font-size: 18px;
  line-height: 2.25; }
  @media screen and (max-width: 768px) {
    main p {
      font-size: 15px; } }

.pc {
  display: block; }
  @media screen and (max-width: 768px) {
    .pc {
      display: none; } }

.sp {
  display: none; }
  @media screen and (max-width: 768px) {
    .sp {
      display: block; } }
