img {
  width: 100%;
  height: auto; }

main#genten {
  background-repeat: repeat;
  overflow: hidden; }
  main#genten .pc {
    display: block; }
    @media screen and (max-width: 768px) {
      main#genten .pc {
        display: none; } }
  @media screen and (min-width: 769px) {
    main#genten .smh {
      display: none; } }
  @media screen and (max-width: 768px) {
    main#genten .smh {
      display: block; } }
  main#genten .inner-article-1 {
    max-width: 1366px;
    width: calc(90% - 10px);
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      main#genten .inner-article-1 {
        width: 100%;
        padding: 0 1em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box; } }
  main#genten .cercle {
    content: "";
    display: block;
    width: 1000px;
    height: 1000px;
    border: 50px solid #d6d148;
    border-radius: 50%;
    opacity: 0.8;
    position: absolute;
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    top: 10vh;
    left: 0%;
    z-index: 0; }
  main#genten h1 {
    color: #546a44;
    font-size: 30px;
    padding: 1.5em 1em;
    margin-top: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative; }
    @media screen and (max-width: 768px) {
      main#genten h1 {
        font-size: 25px;
        margin-top: 20px; } }
    @media screen and (max-width: 576px) {
      main#genten h1 {
        font-size: 19px; } }
    main#genten h1 .box1,
    main#genten h1 .box2 {
      content: "";
      display: block;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: 2em;
      border: 2px solid #555;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    main#genten h1 .box1 {
      z-index: 2;
      border-color: #fff;
      top: calc(50% - 2.5px);
      left: calc(50% - 2.5px); }
    main#genten h1 .box2 {
      z-index: 1;
      border-color: #546a44;
      top: calc(50% + 2.5px);
      left: calc(50% + 2.5px); }
    main#genten h1 img {
      width: 120px;
      position: absolute;
      left: -30%;
      top: 0;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        main#genten h1 img {
          width: 80px; } }
  main#genten .white {
    color: #fff; }
  main#genten p,
  main#genten button {
    color: #546a44;
    font-size: 22px;
    line-height: 2;
    margin-bottom: 1em; }
    @media screen and (max-width: 768px) {
      main#genten p,
      main#genten button {
        font-size: 17px; } }
    @media screen and (max-width: 576px) {
      main#genten p,
      main#genten button {
        font-size: 16px; } }
  main#genten .small {
    font-size: 17px;
    line-height: 2.4;
    margin-bottom: 1em; }
    @media screen and (max-width: 768px) {
      main#genten .small {
        font-size: 15px; } }
    @media screen and (max-width: 576px) {
      main#genten .small {
        font-size: 14px; } }
  main#genten .hurf-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    @media screen and (max-width: 992px) {
      main#genten .hurf-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }
    main#genten .hurf-wrap .box {
      width: 50%; }
  main#genten .area1 {
    position: relative; }
    @media screen and (max-width: 768px) {
      main#genten .area1.inner-article-1 {
        padding: 0; } }
    main#genten .area1 figure {
      padding: 50px 0;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      z-index: 1; }
      main#genten .area1 figure img {
        width: 100%; }
    @media screen and (max-width: 768px) {
      main#genten .area1 .cercle {
        top: 5vh;
        width: 300px;
        height: 300px;
        border: 25px solid #d6d148; } }
  main#genten .area2 {
    position: relative;
    z-index: 1; }
  main#genten .area3 {
    position: relative;
    z-index: 1;
    background-color: #464646;
    margin-top: 100px; }
    main#genten .area3 .box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      main#genten .area3 .box:nth-child(1) {
        width: 50%;
        background-position: 50% 0;
        background-size: 100% auto; }
        @media screen and (max-width: 992px) {
          main#genten .area3 .box:nth-child(1) {
            width: 100%;
            margin-bottom: 2em; } }
        main#genten .area3 .box:nth-child(1):before {
          content: "";
          display: block;
          padding-top: 110%; }
          @media screen and (max-width: 992px) {
            main#genten .area3 .box:nth-child(1):before {
              padding-top: 80%; } }
      main#genten .area3 .box:nth-child(2) {
        padding: 0 3em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box; }
        main#genten .area3 .box:nth-child(2) h1 {
          margin-top: 0; }
        @media screen and (max-width: 992px) {
          main#genten .area3 .box:nth-child(2) {
            width: 100%;
            padding: 3em 0 0; } }
  main#genten .area4 {
    position: relative;
    z-index: 1; }
    main#genten .area4 .block2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      main#genten .area4 .block2 > *:nth-child(1) {
        width: 60%; }
      main#genten .area4 .block2 > *:nth-child(2) {
        width: 35%; }
      @media screen and (max-width: 768px) {
        main#genten .area4 .block2 {
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; }
          main#genten .area4 .block2 > *:nth-child(1) {
            width: 100%; }
          main#genten .area4 .block2 > *:nth-child(2) {
            width: 70% !important; } }
  main#genten .area5 {
    position: relative;
    z-index: 1; }
    main#genten .area5 figure {
      margin-top: 50px; }
      @media screen and (max-width: 768px) {
        main#genten .area5 figure {
          margin-top: 20px; } }
  main#genten .area6 {
    position: relative;
    z-index: 1; }
    main#genten .area6 .block1 {
      position: relative;
      z-index: 2; }
      main#genten .area6 .block1 figure {
        max-width: 1000px;
        margin: 50px auto 0; }
        @media screen and (max-width: 768px) {
          main#genten .area6 .block1 figure {
            margin: 20px auto 0; } }
    main#genten .area6 .type-1 {
      z-index: 1;
      border-width: 30px;
      width: 700px;
      height: 700px; }
      @media screen and (max-width: 768px) {
        main#genten .area6 .type-1 {
          width: 300px;
          height: 300px;
          top: 40vh; } }
    main#genten .area6 .type-2 {
      z-index: 2;
      border-width: 15px;
      width: 500px;
      height: 500px;
      top: inherit;
      bottom: -370px;
      left: 93%; }
      @media screen and (max-width: 768px) {
        main#genten .area6 .type-2 {
          width: 100px;
          height: 100px;
          top: inherit;
          bottom: -75px; } }
  main#genten .area7 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    max-width: 1200px;
    padding: 2em 0;
    margin-top: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    main#genten .area7 address {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      max-width: 1000px;
      margin: 0 auto; }
      @media screen and (max-width: 1000px) {
        main#genten .area7 address {
          padding: 0 1em;
          -webkit-box-sizing: border-box;
          box-sizing: border-box; } }
      main#genten .area7 address p {
        width: 100%; }
        main#genten .area7 address p.map {
          color: #ef4c4c; }
        main#genten .area7 address p.tel {
          color: #20a248; }
        main#genten .area7 address p.mail {
          color: #77a3c9; }
        main#genten .area7 address p a {
          padding-left: 0.5em;
          color: #000; }
      main#genten .area7 address iframe {
        width: 100%;
        height: 300px; }
  main#genten .hp-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    main#genten .hp-nav a {
      margin-top: 3em; }
      main#genten .hp-nav a button {
        background-color: #fa3f3f;
        color: #fff;
        border: none;
        padding: 0.25em 3.5em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        cursor: pointer; }
        main#genten .hp-nav a button i {
          margin-right: 0.5em; }
        main#genten .hp-nav a button:hover {
          background-color: #333;
          color: #ecd92e; }
