main#sol .w80 {
  width: 80%; }

main#sol h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 18px;
  margin-bottom: 1.75em; }
  @media screen and (max-width: 768px) {
    main#sol h3 {
      font-size: 12px; } }
  main#sol h3 span {
    line-height: 0; }
    main#sol h3 span.img-ttl {
      margin-right: 1em; }
      main#sol h3 span.img-ttl img {
        height: 3.5em;
        width: auto; }
    main#sol h3 span.ttl {
      padding-bottom: 0.5em;
      color: #ff9828; }
      main#sol h3 span.ttl:before, main#sol h3 span.ttl:after {
        content: "-"; }
      main#sol h3 span.ttl:before {
        margin-right: 0.5em; }
      main#sol h3 span.ttl:after {
        margin-left: 0.5em; }

main#sol .area2,
main#sol .area3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#sol .area2,
    main#sol .area3 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      margin-top: 50px; } }
  main#sol .area2 .box,
  main#sol .area3 .box {
    width: 50%;
    padding: 10px; }
    @media screen and (max-width: 768px) {
      main#sol .area2 .box,
      main#sol .area3 .box {
        width: 100%; } }
  main#sol .area2 .img-box,
  main#sol .area3 .img-box {
    text-align: center; }
    main#sol .area2 .img-box img,
    main#sol .area3 .img-box img {
      width: 90%; }

main#sol .area3 {
  background-position: 0% 0;
  background-size: 100% 100%; }
  main#sol .area3 .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    padding-bottom: 100px; }
    @media screen and (max-width: 768px) {
      main#sol .area3 .inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }
    main#sol .area3 .inner .img-box img {
      width: 100%; }

main#sol .area4 {
  margin-top: 100px; }

main#sol .area5 {
  margin-top: 100px; }

main#sol .area6 {
  background-position: 0% 0;
  background-size: 100% 100%;
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    main#sol .area6 {
      padding: 50px 0; } }
  main#sol .area6 figure {
    text-align: center; }
    main#sol .area6 figure img {
      width: 80%; }
