@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,500;1,100;1,200;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,500;1,100;1,200;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,600&display=swap);
img {
  width: 100%;
  height: auto; }

#topHeader {
  padding: 100px 0 35px 0; }
  @media screen and (max-width: 768px) {
    #topHeader {
      padding: 50px 0 50px 0; } }
  #topHeader .hd-logo {
    width: 75%;
    max-width: 450px;
    margin: 0 auto; }

#topFooter {
  margin-top: 65px;
  padding: 15px 15px;
  background-color: #181818; }
  #topFooter .menu-main-wrap .menu-main {
    height: 80px;
    background-color: #181818;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center; }
    #topFooter .menu-main-wrap .menu-main .ft-logo {
      width: 220px; }
    #topFooter .menu-main-wrap .menu-main ul {
      display: none !important;
      display: flex;
      justify-content: flex-end; }
      #topFooter .menu-main-wrap .menu-main ul a {
        display: flex;
        align-items: center; }
        #topFooter .menu-main-wrap .menu-main ul a li {
          color: #fff; }
          #topFooter .menu-main-wrap .menu-main ul a li p {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            font-size: 15px;
            letter-spacing: 1px; }
            #topFooter .menu-main-wrap .menu-main ul a li p span {
              width: 100%;
              text-align: center;
              font-size: 8px;
              margin-top: 6px;
              letter-spacing: 0px; }
      #topFooter .menu-main-wrap .menu-main ul a.sns-link {
        margin-right: 10px; }
      #topFooter .menu-main-wrap .menu-main ul a.sns-link:last-child {
        margin-right: 0; }

img {
  width: 100%;
  height: auto; }

#topArchive { }
  #topArchive h1,
  #topArchive h2,
  #topArchive h3,
  #topArchive h4,
  #topArchive h5,
  #topArchive li,
  #topArchive dt,
  #topArchive dd,
  #topArchive p,
  #topArchive label,
  #topArchive button,
  #topArchive input,
  #topArchive textarea,
  #topArchive select,
  #topArchive dt,
  #topArchive dd {
    font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important; }
  #topArchive a {
    color: inherit;
    text-decoration: none; }
  #topArchive img {
    width: 100%;
    height: auto; }
  #topArchive .inner-1 {
    width: 100%;
    max-width: 1306px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box; }
  @media screen and (max-width: 1175px) {
    #topArchive .inner-1 {
      padding: 0; } }
  #topArchive #loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #topArchive #loader .spring-spinner,
  #topArchive #loader .spring-spinner * {
    box-sizing: border-box; }
  #topArchive #loader .spring-spinner {
    height: 30px;
    width: 30px; }
  #topArchive #loader .spring-spinner .spring-spinner-part {
    overflow: hidden;
    height: calc(30px / 2);
    width: 30px; }
  #topArchive #loader .spring-spinner .spring-spinner-part.bottom {
    transform: rotate(180deg) scale(-1, 1); }
  #topArchive #loader .spring-spinner .spring-spinner-rotator {
    width: 30px;
    height: 30px;
    border: calc(20px / 7) solid transparent;
    border-right-color: #ececec;
    border-top-color: #dfdfdf;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spring-spinner-animation 3s ease-in-out infinite;
    transform: rotate(-200deg); }

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7); }
  25% {
    border-width: calc(60px / 23.33); }
  50% {
    transform: rotate(115deg);
    border-width: calc(60px / 7); }
  75% {
    border-width: calc(60px / 23.33); }
  100% {
    border-width: calc(60px / 7); } }
  #topArchive .big-banner-wrap {
    margin-bottom: 65px;
    position: relative; }
    #topArchive .big-banner-wrap .big-banner {
      background: linear-gradient(#fefefe, #fafafa);
      margin: 0 auto 0;
      overflow: hidden; }
  #topArchive .slider__inner {
    position: relative;
    overflow: hidden; }
    #topArchive .slider__inner canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  #topArchive .slider-bullet {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  #topArchive .slider-bullet__text {
    color: #fff;
    font-size: 0.65rem;
    margin-right: 1rem; }
  #topArchive .slider-bullet__line {
    background-color: #fff;
    height: 1px;
    width: 1rem; }
  #topArchive .slide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden; }
  #topArchive .slider__text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    font-size: 12px;
    text-transform: uppercase;
    transform-origin: top;
    line-height: 1.075;
    color: #000;
    font-weight: 500; }
  #topArchive .slider__text-line {
    overflow: hidden;
    font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important; }
  #topArchive .slider__nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 10; }
  #topArchive .slider-bullet {
    display: flex;
    align-items: center;
    padding: 1rem 0; }
  #topArchive .slider-bullet__text {
    color: #555;
    font-size: 0.65rem;
    margin-right: 1rem; }
  #topArchive .slider-bullet__line {
    background-color: #555;
    height: 1px;
    width: 1rem; }
  #topArchive article {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    #topArchive article {
      padding: 0 5px; } }
  #topArchive article section {
    width: 23%;
    box-sizing: border-box;
    margin: 0 1% 1.5em; }
    @media screen and (max-width: 768px) {
      #topArchive article section {
        width: 49%;
        margin: 0 0.5% 3em; } }
    #topArchive article section figure {
      margin-bottom: 0.5em;
      position: relative;
      cursor: pointer; }
      #topArchive article section figure:before {
        content: "";
        display: block;
        padding-top: 75%;
        background: #fff;
        transition-duration: 0.5s; }
      @media screen and (min-width: 769px) {
        #topArchive article section figure:hover img {
          transform: translate(-52%, -52%);
          box-shadow: 3px 3px 10px #00000050; } }
      #topArchive article section figure img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition-duration: 0.5s; }
    #topArchive article section h1 {
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      color: #555555;
      margin-bottom: 0.5em;
      font-family: "Prompt", sans-serif !important; }
    #topArchive article section ul {
      width: 100%;
      display: flex;
      justify-content: flex-end; }
    #topArchive article section li {
      font-family: "Prompt", sans-serif !important;
      font-size: 10px;
      font-weight: bold;
      margin-right: 0.5em;
      color: #555555;
      white-space: nowrap; }

#articleFooter {
  position: fixed;
  z-index: 999;
  bottom: 10px;
  right: 10px;
  width: 170px; }

h1,
h2,
h3,
h4,
h5,
li,
dt,
dd,
p,
label,
button,
input,
textarea,
select,
dt,
dd {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important; }

a {
  color: inherit;
  text-decoration: none; }

img {
  width: 100%;
  height: auto; }

.inner-1 {
  width: 100%;
  max-width: 1306px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box; }

@media screen and (max-width: 1175px) {
  .inner-1 {
    padding: 0; } }

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

#loader .spring-spinner,
#loader .spring-spinner * {
  box-sizing: border-box; }

#loader .spring-spinner {
  height: 30px;
  width: 30px; }

#loader .spring-spinner .spring-spinner-part {
  overflow: hidden;
  height: calc(30px / 2);
  width: 30px; }

#loader .spring-spinner .spring-spinner-part.bottom {
  transform: rotate(180deg) scale(-1, 1); }

#loader .spring-spinner .spring-spinner-rotator {
  width: 30px;
  height: 30px;
  border: calc(20px / 7) solid transparent;
  border-right-color: #ececec;
  border-top-color: #dfdfdf;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-animation 3s ease-in-out infinite;
  transform: rotate(-200deg); }

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7); }
  25% {
    border-width: calc(60px / 23.33); }
  50% {
    transform: rotate(115deg);
    border-width: calc(60px / 7); }
  75% {
    border-width: calc(60px / 23.33); }
  100% {
    border-width: calc(60px / 7); } }

header {
  padding: 100px 0 35px 0; }

header .hd-logo {
  width: 75%;
  max-width: 450px;
  margin: 0 auto; }

footer {
  margin-top: 65px;
  padding-top: 15px;
  background-color: #181818; }

footer .menu-main-wrap .menu-main {
  height: 80px;
  background-color: #181818;
  display: flex;
  justify-content: space-between;
  align-items: center; }

footer .menu-main-wrap .menu-main .ft-logo {
  width: 220px; }

footer .menu-main-wrap .menu-main ul {
  display: flex;
  justify-content: flex-end; }

footer .menu-main-wrap .menu-main ul a {
  display: flex;
  align-items: center; }

footer .menu-main-wrap .menu-main ul a.sns-link {
  margin-right: 10px; }

footer .menu-main-wrap .menu-main ul a.sns-link:last-child {
  margin-right: 0; }

footer .menu-main-wrap .menu-main ul a li {
  color: #fff; }

footer .menu-main-wrap .menu-main ul a li p {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 1px; }

footer .menu-main-wrap .menu-main ul a li p span {
  width: 100%;
  text-align: center;
  font-size: 8px;
  margin-top: 6px;
  letter-spacing: 0px; }

.inner {
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  box-sizing: border-box; }

#studio-detail {
  margin: 50px 0; }
  #studio-detail .studio-box {
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 25px #00000020; }
    #studio-detail .studio-box p {
      font-weight: 600 !important;
      width: 100%;
      display: inline-flex; }
      #studio-detail .studio-box p.studioName {
        padding-left: 1em; }
      #studio-detail .studio-box p.address img {
        width: 12px; }
      #studio-detail .studio-box p.tel img {
        width: 18px; }
      #studio-detail .studio-box p.mail img {
        width: 18px; }
      #studio-detail .studio-box p span {
        width: 30px;
        text-align: center; }
    #studio-detail .studio-box iframe {
      width: 100%;
      height: 300px; }
  #studio-detail .studio-btn {
    margin-top: 50px;
    text-align: center; }
    #studio-detail .studio-btn .studio-link {
      cursor: pointer;
      content: ">";
      background-color: #fa3f3f;
      font-size: 25px;
      color: #fff;
      border: none;
      padding: 0.5em;
      width: 95%;
      max-width: 600px; }
      @media screen and (max-width: 768px) {
        #studio-detail .studio-btn .studio-link {
          font-size: 18px; } }

main p,
main h3 {
  font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
  font-weight: 500; }

main p {
  font-size: 18px;
  line-height: 2.25; }
  @media screen and (max-width: 768px) {
    main p {
      font-size: 15px; } }

.pc {
  display: block; }
  @media screen and (max-width: 768px) {
    .pc {
      display: none; } }

.sp {
  display: none; }
  @media screen and (max-width: 768px) {
    .sp {
      display: block; } }

#yushinkai .ttl-box {
  position: relative;
  margin-bottom: 20px; }
  #yushinkai .ttl-box h3 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 30px;
    border: 3px solid #e9d05b;
    position: relative;
    padding: 14px 2em 10px; }
    @media screen and (max-width: 768px) {
      #yushinkai .ttl-box h3 {
        font-size: 23px; } }
    #yushinkai .ttl-box h3:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border: 3px solid #fff;
      position: absolute;
      top: 2px;
      left: 2px; }
  #yushinkai .ttl-box .sub-ttl {
    position: absolute;
    top: -40px;
    left: -10px;
    width: 100px; }

#yushinkai .area2 {
  position: relative;
  padding: 100px 0;
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  z-index: 2;
  background-size: cover;
  background-position: 50% 50%; }
  @media screen and (max-width: 768px) {
    #yushinkai .area2 {
      padding: 50px 0;
      -webkit-clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
      clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%); } }
  #yushinkai .area2 .ttl-box h3 {
    color: #fff; }
  #yushinkai .area2 p {
    color: #fff;
    text-shadow: 2px 2px black; }

#yushinkai .area3 {
  position: relative;
  z-index: 1;
  margin-top: -80px;
  -webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 85%);
  clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 85%);
  background: #b98000;
  padding: 100px 0;
  background: linear-gradient(90deg, #b98000 0%, #b98000 100%); }
  @media screen and (max-width: 768px) {
    #yushinkai .area3 {
      padding: 130px 0 50px;
      -webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 95%);
      clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 95%); } }
  #yushinkai .area3 .inner {
    display: flex;
    align-items: center;
    background-size: 50% auto;
    background-repeat: no-repeat;
    background-position: 0 50%; }
    @media screen and (max-width: 768px) {
      #yushinkai .area3 .inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse; } }
    #yushinkai .area3 .inner .box {
      width: 60%; }
      #yushinkai .area3 .inner .box:nth-child(1) {
        max-width: 600px; }
      @media screen and (max-width: 768px) {
        #yushinkai .area3 .inner .box {
          width: 100%; }
          #yushinkai .area3 .inner .box:nth-child(1) {
            width: 80%;
            margin: 0 auto; } }
      #yushinkai .area3 .inner .box:nth-child(2) {
        margin-left: -10%; }
        @media screen and (max-width: 768px) {
          #yushinkai .area3 .inner .box:nth-child(2) {
            margin-left: 0%;
            width: 100%; } }
      #yushinkai .area3 .inner .box h3 {
        color: #fff; }
      #yushinkai .area3 .inner .box p {
        color: #fff;
        text-shadow: 2px 2px black; }

#yushinkai .area4 {
  padding: 100px 0; }

#yushinkai .area5 {
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    #yushinkai .area5 {
      -webkit-clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
      clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%); } }
  #yushinkai .area5 h3,
  #yushinkai .area5 p {
    color: #fff;
    margin-bottom: 50px; }
  #yushinkai .area5 .staff-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    @media screen and (max-width: 768px) {
      #yushinkai .area5 .staff-list {
        justify-content: space-between; } }
    #yushinkai .area5 .staff-list figure {
      width: 270px;
      border-radius: 999px;
      background-color: #fff;
      overflow: hidden; }
      @media screen and (max-width: 768px) {
        #yushinkai .area5 .staff-list figure {
          width: 30%; } }

#yushinkai .area6 {
  padding: 100px 0; }

img {
  width: 100%;
  height: auto; }

main#genten {
  background-repeat: repeat;
  overflow: hidden; }
  main#genten .pc {
    display: block; }
    @media screen and (max-width: 768px) {
      main#genten .pc {
        display: none; } }
  @media screen and (min-width: 769px) {
    main#genten .smh {
      display: none; } }
  @media screen and (max-width: 768px) {
    main#genten .smh {
      display: block; } }
  main#genten .inner-article-1 {
    max-width: 1366px;
    width: calc(90% - 10px);
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      main#genten .inner-article-1 {
        width: 100%;
        padding: 0 1em;
        box-sizing: border-box; } }
  main#genten .cercle {
    content: "";
    display: block;
    width: 1000px;
    height: 1000px;
    border: 50px solid #d6d148;
    border-radius: 50%;
    opacity: 0.8;
    position: absolute;
    transform: translate(-50%, 0%);
    top: 10vh;
    left: 0%;
    z-index: 0; }
  main#genten h1 {
    color: #546a44;
    font-size: 30px;
    padding: 1.5em 1em;
    margin-top: 50px;
    box-sizing: border-box;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative; }
    @media screen and (max-width: 768px) {
      main#genten h1 {
        font-size: 25px;
        margin-top: 20px; } }
    @media screen and (max-width: 576px) {
      main#genten h1 {
        font-size: 19px; } }
    main#genten h1 .box1,
    main#genten h1 .box2 {
      content: "";
      display: block;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: 2em;
      border: 2px solid #555;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    main#genten h1 .box1 {
      z-index: 2;
      border-color: #fff;
      top: calc(50% - 2.5px);
      left: calc(50% - 2.5px); }
    main#genten h1 .box2 {
      z-index: 1;
      border-color: #546a44;
      top: calc(50% + 2.5px);
      left: calc(50% + 2.5px); }
    main#genten h1 img {
      width: 120px;
      position: absolute;
      left: -30%;
      top: 0;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        main#genten h1 img {
          width: 80px; } }
  main#genten .white {
    color: #fff; }
  main#genten p,
  main#genten button {
    color: #546a44;
    font-size: 22px;
    line-height: 2;
    margin-bottom: 1em; }
    @media screen and (max-width: 768px) {
      main#genten p,
      main#genten button {
        font-size: 17px; } }
    @media screen and (max-width: 576px) {
      main#genten p,
      main#genten button {
        font-size: 16px; } }
  main#genten .small {
    font-size: 17px;
    line-height: 2.4;
    margin-bottom: 1em; }
    @media screen and (max-width: 768px) {
      main#genten .small {
        font-size: 15px; } }
    @media screen and (max-width: 576px) {
      main#genten .small {
        font-size: 14px; } }
  main#genten .hurf-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    @media screen and (max-width: 992px) {
      main#genten .hurf-wrap {
        flex-direction: column-reverse; } }
    main#genten .hurf-wrap .box {
      width: 50%; }
  main#genten .area1 {
    position: relative; }
    @media screen and (max-width: 768px) {
      main#genten .area1.inner-article-1 {
        padding: 0; } }
    main#genten .area1 figure {
      padding: 50px 0;
      position: relative;
      display: flex;
      align-items: center;
      z-index: 1; }
      main#genten .area1 figure img {
        width: 100%; }
    @media screen and (max-width: 768px) {
      main#genten .area1 .cercle {
        top: 5vh;
        width: 300px;
        height: 300px;
        border: 25px solid #d6d148; } }
  main#genten .area2 {
    position: relative;
    z-index: 1; }
  main#genten .area3 {
    position: relative;
    z-index: 1;
    background-color: #464646;
    margin-top: 100px; }
    main#genten .area3 .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      main#genten .area3 .box:nth-child(1) {
        width: 50%;
        background-position: 50% 0;
        background-size: 100% auto; }
        @media screen and (max-width: 992px) {
          main#genten .area3 .box:nth-child(1) {
            width: 100%;
            margin-bottom: 2em; } }
        main#genten .area3 .box:nth-child(1):before {
          content: "";
          display: block;
          padding-top: 110%; }
          @media screen and (max-width: 992px) {
            main#genten .area3 .box:nth-child(1):before {
              padding-top: 80%; } }
      main#genten .area3 .box:nth-child(2) {
        padding: 0 3em;
        box-sizing: border-box; }
        main#genten .area3 .box:nth-child(2) h1 {
          margin-top: 0; }
        @media screen and (max-width: 992px) {
          main#genten .area3 .box:nth-child(2) {
            width: 100%;
            padding: 3em 0 0; } }
  main#genten .area4 {
    position: relative;
    z-index: 1; }
    main#genten .area4 .block2 {
      display: flex;
      justify-content: space-between; }
      main#genten .area4 .block2 > *:nth-child(1) {
        width: 60%; }
      main#genten .area4 .block2 > *:nth-child(2) {
        width: 35%; }
      @media screen and (max-width: 768px) {
        main#genten .area4 .block2 {
          flex-wrap: wrap;
          justify-content: center; }
          main#genten .area4 .block2 > *:nth-child(1) {
            width: 100%; }
          main#genten .area4 .block2 > *:nth-child(2) {
            width: 70% !important; } }
  main#genten .area5 {
    position: relative;
    z-index: 1; }
    main#genten .area5 figure {
      margin-top: 50px; }
      @media screen and (max-width: 768px) {
        main#genten .area5 figure {
          margin-top: 20px; } }
  main#genten .area6 {
    position: relative;
    z-index: 1; }
    main#genten .area6 .block1 {
      position: relative;
      z-index: 2; }
      main#genten .area6 .block1 figure {
        max-width: 1000px;
        margin: 50px auto 0; }
        @media screen and (max-width: 768px) {
          main#genten .area6 .block1 figure {
            margin: 20px auto 0; } }
    main#genten .area6 .type-1 {
      z-index: 1;
      border-width: 30px;
      width: 700px;
      height: 700px; }
      @media screen and (max-width: 768px) {
        main#genten .area6 .type-1 {
          width: 300px;
          height: 300px;
          top: 40vh; } }
    main#genten .area6 .type-2 {
      z-index: 2;
      border-width: 15px;
      width: 500px;
      height: 500px;
      top: inherit;
      bottom: -370px;
      left: 93%; }
      @media screen and (max-width: 768px) {
        main#genten .area6 .type-2 {
          width: 100px;
          height: 100px;
          top: inherit;
          bottom: -75px; } }
  main#genten .area7 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    max-width: 1200px;
    padding: 2em 0;
    margin-top: 50px;
    box-sizing: border-box; }
    main#genten .area7 address {
      display: flex;
      flex-wrap: wrap;
      max-width: 1000px;
      margin: 0 auto; }
      @media screen and (max-width: 1000px) {
        main#genten .area7 address {
          padding: 0 1em;
          box-sizing: border-box; } }
      main#genten .area7 address p {
        width: 100%; }
        main#genten .area7 address p.map {
          color: #ef4c4c; }
        main#genten .area7 address p.tel {
          color: #20a248; }
        main#genten .area7 address p.mail {
          color: #77a3c9; }
        main#genten .area7 address p a {
          padding-left: 0.5em;
          color: #000; }
      main#genten .area7 address iframe {
        width: 100%;
        height: 300px; }
  main#genten .hp-nav {
    display: flex;
    justify-content: center; }
    main#genten .hp-nav a {
      margin-top: 3em; }
      main#genten .hp-nav a button {
        background-color: #fa3f3f;
        color: #fff;
        border: none;
        padding: 0.25em 3.5em;
        box-sizing: border-box;
        transition-duration: 0.5s;
        cursor: pointer; }
        main#genten .hp-nav a button i {
          margin-right: 0.5em; }
        main#genten .hp-nav a button:hover {
          background-color: #333;
          color: #ecd92e; }

main#sol .w80 {
  width: 80%; }

main#sol h3 {
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  margin-bottom: 1.75em; }
  @media screen and (max-width: 768px) {
    main#sol h3 {
      font-size: 12px; } }
  main#sol h3 span {
    line-height: 0; }
    main#sol h3 span.img-ttl {
      margin-right: 1em; }
      main#sol h3 span.img-ttl img {
        height: 3.5em;
        width: auto; }
    main#sol h3 span.ttl {
      padding-bottom: 0.5em;
      color: #ff9828; }
      main#sol h3 span.ttl:before, main#sol h3 span.ttl:after {
        content: "-"; }
      main#sol h3 span.ttl:before {
        margin-right: 0.5em; }
      main#sol h3 span.ttl:after {
        margin-left: 0.5em; }

main#sol .area2,
main#sol .area3 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#sol .area2,
    main#sol .area3 {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-top: 50px; } }
  main#sol .area2 .box,
  main#sol .area3 .box {
    width: 50%;
    padding: 10px; }
    @media screen and (max-width: 768px) {
      main#sol .area2 .box,
      main#sol .area3 .box {
        width: 100%; } }
  main#sol .area2 .img-box,
  main#sol .area3 .img-box {
    text-align: center; }
    main#sol .area2 .img-box img,
    main#sol .area3 .img-box img {
      width: 90%; }

main#sol .area3 {
  background-position: 0% 0;
  background-size: 100% 100%; }
  main#sol .area3 .inner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    padding-bottom: 100px; }
    @media screen and (max-width: 768px) {
      main#sol .area3 .inner {
        flex-wrap: wrap;
        flex-direction: column-reverse; } }
    main#sol .area3 .inner .img-box img {
      width: 100%; }

main#sol .area4 {
  margin-top: 100px; }

main#sol .area5 {
  margin-top: 100px; }

main#sol .area6 {
  background-position: 0% 0;
  background-size: 100% 100%;
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    main#sol .area6 {
      padding: 50px 0; } }
  main#sol .area6 figure {
    text-align: center; }
    main#sol .area6 figure img {
      width: 80%; }

main#nostyle {
  background-position: 0% 50%;
  background-size: contain; }
  main#nostyle .line {
    margin-top: 50px; }
  main#nostyle .w80 {
    width: 80%; }
  main#nostyle h3 {
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 0.5em;
    font-family: "Montserrat", sans-serif !important; }
    @media screen and (max-width: 768px) {
      main#nostyle h3 {
        font-size: 26px; } }
    main#nostyle h3 span {
      line-height: 1;
      font-size: 18px;
      margin-left: 1em; }
      @media screen and (max-width: 768px) {
        main#nostyle h3 span {
          font-size: 14px; } }
      main#nostyle h3 span.ttl:before, main#nostyle h3 span.ttl:after {
        content: "-"; }
      main#nostyle h3 span.ttl:before {
        margin-right: 0.5em; }
      main#nostyle h3 span.ttl:after {
        margin-left: 0.5em; }
  main#nostyle .area2,
  main#nostyle .area3,
  main#nostyle .area5 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      main#nostyle .area2,
      main#nostyle .area3,
      main#nostyle .area5 {
        margin-top: 50px; } }
    @media screen and (max-width: 768px) {
      main#nostyle .area2,
      main#nostyle .area3,
      main#nostyle .area5 {
        flex-wrap: wrap;
        margin-top: 50px; } }
    main#nostyle .area2 .box,
    main#nostyle .area3 .box,
    main#nostyle .area5 .box {
      width: 50%;
      padding: 10px; }
      @media screen and (max-width: 768px) {
        main#nostyle .area2 .box,
        main#nostyle .area3 .box,
        main#nostyle .area5 .box {
          width: 100%; } }
    main#nostyle .area2 .img-box,
    main#nostyle .area3 .img-box,
    main#nostyle .area5 .img-box {
      text-align: center; }
      main#nostyle .area2 .img-box img,
      main#nostyle .area3 .img-box img,
      main#nostyle .area5 .img-box img {
        width: 90%; }
  main#nostyle .img-box img {
    width: 80%; }
  main#nostyle .area3 {
    background-position: 0% 0;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row-reverse; }
    @media screen and (max-width: 768px) {
      main#nostyle .area3 {
        flex-wrap: wrap; } }
  main#nostyle .area4,
  main#nostyle .area6 {
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      main#nostyle .area4,
      main#nostyle .area6 {
        margin-top: 50px; } }
    main#nostyle .area4 figure,
    main#nostyle .area6 figure {
      margin-top: 50px; }
  main#nostyle .area5 {
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      main#nostyle .area5 {
        margin-top: 50px; } }
  main#nostyle .area6 {
    background-position: 0% 0;
    background-size: 100% 100%;
    padding: 100px 0; }
    @media screen and (max-width: 768px) {
      main#nostyle .area6 {
        padding: 50px 0; } }
    main#nostyle .area6 figure {
      text-align: center; }
      main#nostyle .area6 figure img {
        width: 100%; }

main#Littlemysore .w80 {
  width: 80%; }

main#Littlemysore h3 {
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-bottom: 0.5em;
  font-family: "Montserrat", sans-serif !important; }
  @media screen and (max-width: 768px) {
    main#Littlemysore h3 {
      justify-content: center;
      text-align: center;
      width: 100%;
      font-size: 26px;
      flex-wrap: wrap;
      line-height: 1.75; } }
  main#Littlemysore h3 span {
    line-height: 1;
    font-size: 18px;
    margin-left: 1em; }
    @media screen and (max-width: 768px) {
      main#Littlemysore h3 span {
        font-size: 14px; } }
    main#Littlemysore h3 span.ttl:before, main#Littlemysore h3 span.ttl:after {
      content: "-"; }
    main#Littlemysore h3 span.ttl:before {
      margin-right: 0.5em; }
    main#Littlemysore h3 span.ttl:after {
      margin-left: 0.5em; }

main#Littlemysore .area2,
main#Littlemysore .area3,
main#Littlemysore .area5 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area2,
    main#Littlemysore .area3,
    main#Littlemysore .area5 {
      margin-top: 50px; } }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area2,
    main#Littlemysore .area3,
    main#Littlemysore .area5 {
      flex-wrap: wrap;
      margin-top: 50px; } }
  main#Littlemysore .area2 .box,
  main#Littlemysore .area3 .box,
  main#Littlemysore .area5 .box {
    width: 50%;
    padding: 10px; }
    @media screen and (max-width: 768px) {
      main#Littlemysore .area2 .box,
      main#Littlemysore .area3 .box,
      main#Littlemysore .area5 .box {
        width: 100%; } }
  main#Littlemysore .area2 .img-box,
  main#Littlemysore .area3 .img-box,
  main#Littlemysore .area5 .img-box {
    text-align: center; }
    main#Littlemysore .area2 .img-box img,
    main#Littlemysore .area3 .img-box img,
    main#Littlemysore .area5 .img-box img {
      width: 90%; }

main#Littlemysore .img-box img {
  width: 80%; }

main#Littlemysore .area3 {
  background-position: 0% 0;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row-reverse; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area3 {
      flex-wrap: wrap; } }

main#Littlemysore .area4,
main#Littlemysore .area6 {
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area4,
    main#Littlemysore .area6 {
      margin-top: 50px; } }
  main#Littlemysore .area4 figure,
  main#Littlemysore .area6 figure {
    margin-top: 50px; }

main#Littlemysore .area5 {
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area5 {
      margin-top: 50px; } }

main#Littlemysore .area6 {
  background-position: 0% 0;
  background-size: 100% 100%;
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area6 {
      padding: 50px 0; } }
  main#Littlemysore .area6 figure {
    text-align: center; }
    main#Littlemysore .area6 figure img {
      width: 100%; }

main#Littlemysore .area3,
main#Littlemysore .area5 {
  padding: 100px 0;
  background-size: 100% 100%;
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
  clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%); }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area3,
    main#Littlemysore .area5 {
      padding: 50px 0;
      -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
      clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%); } }
  main#Littlemysore .area3 .inner,
  main#Littlemysore .area5 .inner {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px; }
    @media screen and (max-width: 768px) {
      main#Littlemysore .area3 .inner,
      main#Littlemysore .area5 .inner {
        flex-wrap: wrap;
        margin-top: 50px; } }

/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}

/*スクロールバー全体*/
::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
}

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 50, .5);
  border-radius: 10px;
  box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
}

