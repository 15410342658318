@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,500;1,100;1,200;1,400&display=swap");
img {
  width: 100%;
  height: auto; }

#topArchive { }
  #topArchive h1,
  #topArchive h2,
  #topArchive h3,
  #topArchive h4,
  #topArchive h5,
  #topArchive li,
  #topArchive dt,
  #topArchive dd,
  #topArchive p,
  #topArchive label,
  #topArchive button,
  #topArchive input,
  #topArchive textarea,
  #topArchive select,
  #topArchive dt,
  #topArchive dd {
    font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important; }
  #topArchive a {
    color: inherit;
    text-decoration: none; }
  #topArchive img {
    width: 100%;
    height: auto; }
  #topArchive .inner-1 {
    width: 100%;
    max-width: 1306px;
    padding: 0 15px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  @media screen and (max-width: 1175px) {
    #topArchive .inner-1 {
      padding: 0; } }
  #topArchive #loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  #topArchive #loader .spring-spinner,
  #topArchive #loader .spring-spinner * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  #topArchive #loader .spring-spinner {
    height: 30px;
    width: 30px; }
  #topArchive #loader .spring-spinner .spring-spinner-part {
    overflow: hidden;
    height: calc(30px / 2);
    width: 30px; }
  #topArchive #loader .spring-spinner .spring-spinner-part.bottom {
    -webkit-transform: rotate(180deg) scale(-1, 1);
    -ms-transform: rotate(180deg) scale(-1, 1);
    transform: rotate(180deg) scale(-1, 1); }
  #topArchive #loader .spring-spinner .spring-spinner-rotator {
    width: 30px;
    height: 30px;
    border: calc(20px / 7) solid transparent;
    border-right-color: #ececec;
    border-top-color: #dfdfdf;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: spring-spinner-animation 3s ease-in-out infinite;
    animation: spring-spinner-animation 3s ease-in-out infinite;
    -webkit-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    transform: rotate(-200deg); }

@-webkit-keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7); }
  25% {
    border-width: calc(60px / 23.33); }
  50% {
    -webkit-transform: rotate(115deg);
    transform: rotate(115deg);
    border-width: calc(60px / 7); }
  75% {
    border-width: calc(60px / 23.33); }
  100% {
    border-width: calc(60px / 7); } }

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(60px / 7); }
  25% {
    border-width: calc(60px / 23.33); }
  50% {
    -webkit-transform: rotate(115deg);
    transform: rotate(115deg);
    border-width: calc(60px / 7); }
  75% {
    border-width: calc(60px / 23.33); }
  100% {
    border-width: calc(60px / 7); } }
  #topArchive .big-banner-wrap {
    margin-bottom: 65px;
    position: relative; }
    #topArchive .big-banner-wrap .big-banner {
      background: -webkit-gradient(linear, left top, left bottom, from(#fefefe), to(#fafafa));
      background: -webkit-linear-gradient(#fefefe, #fafafa);
      background: -o-linear-gradient(#fefefe, #fafafa);
      background: linear-gradient(#fefefe, #fafafa);
      margin: 0 auto 0;
      overflow: hidden; }
  #topArchive .slider__inner {
    position: relative;
    overflow: hidden; }
    #topArchive .slider__inner canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  #topArchive .slider-bullet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  #topArchive .slider-bullet__text {
    color: #fff;
    font-size: 0.65rem;
    margin-right: 1rem; }
  #topArchive .slider-bullet__line {
    background-color: #fff;
    height: 1px;
    width: 1rem; }
  #topArchive .slide {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden; }
  #topArchive .slider__text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    font-size: 12px;
    text-transform: uppercase;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    line-height: 1.075;
    color: #000;
    font-weight: 500; }
  #topArchive .slider__text-line {
    overflow: hidden;
    font-family: a-otf-futo-go-b101-pr6n, sans-serif !important;
    font-weight: 500 !important;
    font-style: normal !important; }
  #topArchive .slider__nav {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10; }
  #topArchive .slider-bullet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0; }
  #topArchive .slider-bullet__text {
    color: #555;
    font-size: 0.65rem;
    margin-right: 1rem; }
  #topArchive .slider-bullet__line {
    background-color: #555;
    height: 1px;
    width: 1rem; }
  #topArchive article {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    #topArchive article {
      padding: 0 5px; } }
  #topArchive article section {
    width: 23%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 1% 1.5em; }
    @media screen and (max-width: 768px) {
      #topArchive article section {
        width: 49%;
        margin: 0 0.5% 3em; } }
    #topArchive article section figure {
      margin-bottom: 0.5em;
      position: relative;
      cursor: pointer; }
      #topArchive article section figure:before {
        content: "";
        display: block;
        padding-top: 75%;
        background: #fff;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s; }
      @media screen and (min-width: 769px) {
        #topArchive article section figure:hover img {
          -webkit-transform: translate(-52%, -52%);
          -ms-transform: translate(-52%, -52%);
          transform: translate(-52%, -52%);
          -webkit-box-shadow: 3px 3px 10px #00000050;
          box-shadow: 3px 3px 10px #00000050; } }
      #topArchive article section figure img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s; }
    #topArchive article section h1 {
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      color: #555555;
      margin-bottom: 0.5em;
      font-family: "Prompt", sans-serif !important; }
    #topArchive article section ul {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end; }
    #topArchive article section li {
      font-family: "Prompt", sans-serif !important;
      font-size: 10px;
      font-weight: bold;
      margin-right: 0.5em;
      color: #555555;
      white-space: nowrap; }
