@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,600&display=swap");
main#Littlemysore .w80 {
  width: 80%; }

main#Littlemysore h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 30px;
  margin-bottom: 0.5em;
  font-family: "Montserrat", sans-serif !important; }
  @media screen and (max-width: 768px) {
    main#Littlemysore h3 {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      font-size: 26px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      line-height: 1.75; } }
  main#Littlemysore h3 span {
    line-height: 1;
    font-size: 18px;
    margin-left: 1em; }
    @media screen and (max-width: 768px) {
      main#Littlemysore h3 span {
        font-size: 14px; } }
    main#Littlemysore h3 span.ttl:before, main#Littlemysore h3 span.ttl:after {
      content: "-"; }
    main#Littlemysore h3 span.ttl:before {
      margin-right: 0.5em; }
    main#Littlemysore h3 span.ttl:after {
      margin-left: 0.5em; }

main#Littlemysore .area2,
main#Littlemysore .area3,
main#Littlemysore .area5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area2,
    main#Littlemysore .area3,
    main#Littlemysore .area5 {
      margin-top: 50px; } }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area2,
    main#Littlemysore .area3,
    main#Littlemysore .area5 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-top: 50px; } }
  main#Littlemysore .area2 .box,
  main#Littlemysore .area3 .box,
  main#Littlemysore .area5 .box {
    width: 50%;
    padding: 10px; }
    @media screen and (max-width: 768px) {
      main#Littlemysore .area2 .box,
      main#Littlemysore .area3 .box,
      main#Littlemysore .area5 .box {
        width: 100%; } }
  main#Littlemysore .area2 .img-box,
  main#Littlemysore .area3 .img-box,
  main#Littlemysore .area5 .img-box {
    text-align: center; }
    main#Littlemysore .area2 .img-box img,
    main#Littlemysore .area3 .img-box img,
    main#Littlemysore .area5 .img-box img {
      width: 90%; }

main#Littlemysore .img-box img {
  width: 80%; }

main#Littlemysore .area3 {
  background-position: 0% 0;
  background-size: 100% 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area3 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

main#Littlemysore .area4,
main#Littlemysore .area6 {
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area4,
    main#Littlemysore .area6 {
      margin-top: 50px; } }
  main#Littlemysore .area4 figure,
  main#Littlemysore .area6 figure {
    margin-top: 50px; }

main#Littlemysore .area5 {
  margin-top: 100px; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area5 {
      margin-top: 50px; } }

main#Littlemysore .area6 {
  background-position: 0% 0;
  background-size: 100% 100%;
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area6 {
      padding: 50px 0; } }
  main#Littlemysore .area6 figure {
    text-align: center; }
    main#Littlemysore .area6 figure img {
      width: 100%; }

main#Littlemysore .area3,
main#Littlemysore .area5 {
  padding: 100px 0;
  background-size: 100% 100%;
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%);
  clip-path: polygon(0 15%, 100% 0, 100% 85%, 0% 100%); }
  @media screen and (max-width: 768px) {
    main#Littlemysore .area3,
    main#Littlemysore .area5 {
      padding: 50px 0;
      -webkit-clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%);
      clip-path: polygon(0 5%, 100% 0, 100% 95%, 0% 100%); } }
  main#Littlemysore .area3 .inner,
  main#Littlemysore .area5 .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    margin-top: 50px; }
    @media screen and (max-width: 768px) {
      main#Littlemysore .area3 .inner,
      main#Littlemysore .area5 .inner {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 50px; } }
