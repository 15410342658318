#topFooter {
  margin-top: 65px;
  padding: 15px 15px;
  background-color: #181818; }
  #topFooter .menu-main-wrap .menu-main {
    height: 80px;
    background-color: #181818;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center; }
    #topFooter .menu-main-wrap .menu-main .ft-logo {
      width: 220px; }
    #topFooter .menu-main-wrap .menu-main ul {
      display: none !important;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end; }
      #topFooter .menu-main-wrap .menu-main ul a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        #topFooter .menu-main-wrap .menu-main ul a li {
          color: #fff; }
          #topFooter .menu-main-wrap .menu-main ul a li p {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 15px;
            letter-spacing: 1px; }
            #topFooter .menu-main-wrap .menu-main ul a li p span {
              width: 100%;
              text-align: center;
              font-size: 8px;
              margin-top: 6px;
              letter-spacing: 0px; }
      #topFooter .menu-main-wrap .menu-main ul a.sns-link {
        margin-right: 10px; }
      #topFooter .menu-main-wrap .menu-main ul a.sns-link:last-child {
        margin-right: 0; }
