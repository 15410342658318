@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,600&display=swap");
main#nostyle {
  background-position: 0% 50%;
  background-size: contain; }
  main#nostyle .line {
    margin-top: 50px; }
  main#nostyle .w80 {
    width: 80%; }
  main#nostyle h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 30px;
    margin-bottom: 0.5em;
    font-family: "Montserrat", sans-serif !important; }
    @media screen and (max-width: 768px) {
      main#nostyle h3 {
        font-size: 26px; } }
    main#nostyle h3 span {
      line-height: 1;
      font-size: 18px;
      margin-left: 1em; }
      @media screen and (max-width: 768px) {
        main#nostyle h3 span {
          font-size: 14px; } }
      main#nostyle h3 span.ttl:before, main#nostyle h3 span.ttl:after {
        content: "-"; }
      main#nostyle h3 span.ttl:before {
        margin-right: 0.5em; }
      main#nostyle h3 span.ttl:after {
        margin-left: 0.5em; }
  main#nostyle .area2,
  main#nostyle .area3,
  main#nostyle .area5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      main#nostyle .area2,
      main#nostyle .area3,
      main#nostyle .area5 {
        margin-top: 50px; } }
    @media screen and (max-width: 768px) {
      main#nostyle .area2,
      main#nostyle .area3,
      main#nostyle .area5 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 50px; } }
    main#nostyle .area2 .box,
    main#nostyle .area3 .box,
    main#nostyle .area5 .box {
      width: 50%;
      padding: 10px; }
      @media screen and (max-width: 768px) {
        main#nostyle .area2 .box,
        main#nostyle .area3 .box,
        main#nostyle .area5 .box {
          width: 100%; } }
    main#nostyle .area2 .img-box,
    main#nostyle .area3 .img-box,
    main#nostyle .area5 .img-box {
      text-align: center; }
      main#nostyle .area2 .img-box img,
      main#nostyle .area3 .img-box img,
      main#nostyle .area5 .img-box img {
        width: 90%; }
  main#nostyle .img-box img {
    width: 80%; }
  main#nostyle .area3 {
    background-position: 0% 0;
    background-size: 100% 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
    @media screen and (max-width: 768px) {
      main#nostyle .area3 {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
  main#nostyle .area4,
  main#nostyle .area6 {
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      main#nostyle .area4,
      main#nostyle .area6 {
        margin-top: 50px; } }
    main#nostyle .area4 figure,
    main#nostyle .area6 figure {
      margin-top: 50px; }
  main#nostyle .area5 {
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      main#nostyle .area5 {
        margin-top: 50px; } }
  main#nostyle .area6 {
    background-position: 0% 0;
    background-size: 100% 100%;
    padding: 100px 0; }
    @media screen and (max-width: 768px) {
      main#nostyle .area6 {
        padding: 50px 0; } }
    main#nostyle .area6 figure {
      text-align: center; }
      main#nostyle .area6 figure img {
        width: 100%; }
