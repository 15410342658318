#yushinkai .ttl-box {
  position: relative;
  margin-bottom: 20px; }
  #yushinkai .ttl-box h3 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 30px;
    border: 3px solid #e9d05b;
    position: relative;
    padding: 14px 2em 10px; }
    @media screen and (max-width: 768px) {
      #yushinkai .ttl-box h3 {
        font-size: 23px; } }
    #yushinkai .ttl-box h3:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border: 3px solid #fff;
      position: absolute;
      top: 2px;
      left: 2px; }
  #yushinkai .ttl-box .sub-ttl {
    position: absolute;
    top: -40px;
    left: -10px;
    width: 100px; }

#yushinkai .area2 {
  position: relative;
  padding: 100px 0;
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  z-index: 2;
  background-size: cover;
  background-position: 50% 50%; }
  @media screen and (max-width: 768px) {
    #yushinkai .area2 {
      padding: 50px 0;
      -webkit-clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
      clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%); } }
  #yushinkai .area2 .ttl-box h3 {
    color: #fff; }
  #yushinkai .area2 p {
    color: #fff;
    text-shadow: 2px 2px black; }

#yushinkai .area3 {
  position: relative;
  z-index: 1;
  margin-top: -80px;
  -webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 85%);
  clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 85%);
  background: #b98000;
  padding: 100px 0;
  background: -webkit-gradient(linear, left top, right top, from(#b98000), to(#b98000));
  background: -webkit-linear-gradient(left, #b98000 0%, #b98000 100%);
  background: -o-linear-gradient(left, #b98000 0%, #b98000 100%);
  background: linear-gradient(90deg, #b98000 0%, #b98000 100%); }
  @media screen and (max-width: 768px) {
    #yushinkai .area3 {
      padding: 130px 0 50px;
      -webkit-clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 95%);
      clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 95%); } }
  #yushinkai .area3 .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-size: 50% auto;
    background-repeat: no-repeat;
    background-position: 0 50%; }
    @media screen and (max-width: 768px) {
      #yushinkai .area3 .inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; } }
    #yushinkai .area3 .inner .box {
      width: 60%; }
      #yushinkai .area3 .inner .box:nth-child(1) {
        max-width: 600px; }
      @media screen and (max-width: 768px) {
        #yushinkai .area3 .inner .box {
          width: 100%; }
          #yushinkai .area3 .inner .box:nth-child(1) {
            width: 80%;
            margin: 0 auto; } }
      #yushinkai .area3 .inner .box:nth-child(2) {
        margin-left: -10%; }
        @media screen and (max-width: 768px) {
          #yushinkai .area3 .inner .box:nth-child(2) {
            margin-left: 0%;
            width: 100%; } }
      #yushinkai .area3 .inner .box h3 {
        color: #fff; }
      #yushinkai .area3 .inner .box p {
        color: #fff;
        text-shadow: 2px 2px black; }

#yushinkai .area4 {
  padding: 100px 0; }

#yushinkai .area5 {
  -webkit-clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
  padding: 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0; }
  @media screen and (max-width: 768px) {
    #yushinkai .area5 {
      -webkit-clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
      clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%); } }
  #yushinkai .area5 h3,
  #yushinkai .area5 p {
    color: #fff;
    margin-bottom: 50px; }
  #yushinkai .area5 .staff-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
    @media screen and (max-width: 768px) {
      #yushinkai .area5 .staff-list {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; } }
    #yushinkai .area5 .staff-list figure {
      width: 270px;
      border-radius: 999px;
      background-color: #fff;
      overflow: hidden; }
      @media screen and (max-width: 768px) {
        #yushinkai .area5 .staff-list figure {
          width: 30%; } }

#yushinkai .area6 {
  padding: 100px 0; }
