img {
  width: 100%;
  height: auto; }

#topHeader {
  padding: 100px 0 35px 0; }
  @media screen and (max-width: 768px) {
    #topHeader {
      padding: 50px 0 50px 0; } }
  #topHeader .hd-logo {
    width: 75%;
    max-width: 450px;
    margin: 0 auto; }
